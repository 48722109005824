import { Helmet } from "react-helmet";
import "./about.scss";

const About = () => {
    return (
        <>
            <Helmet>
                <title>О нас</title>
                <meta name="description" content="about information" />
            </Helmet>
            <div className="about">
                <div className="about__content">
                <h2>О нас</h2>
                <p>
                    Здесь представлены уникальные изделия, которые гармонично сочетают в себе качество и стиль. Цель — предложить вещи, которые приносят радость каждый день и подчеркивают индивидуальность. Внимание к деталям и стремление к совершенству — вот главные приоритеты, что делают каждый продукт особенным. Здесь всегда заботятся о том, чтобы ваш опыт покупки был приятным и безупречным.
                </p>
                <p>
                    Продукция создается с вниманием к мельчайшим деталям и с любовью к качеству. Каждый день ставится цель улучшить качество и сервис, чтобы каждый покупатель оставался доволен своим выбором.
                </p>
                <p>
                    <strong>Примечание:</strong> Иногда корзины могут быть не в наличии, в таком случае мы свяжемся с вами и предупредим об этом. Если корзина не готова или не в наличии, или вы решили заказать под свой вкус, будьте в курсе, что на изготовление может уйти от 1 до 7 дней.
                </p>
                </div>
            </div>
        </>
    )
}

export default About;